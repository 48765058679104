import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import ProgressBar from '../components/ProgressBar';
import BookDetails from '../pages/BookDetails';
import { Wizard, useWizard } from 'react-use-wizard';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';


const Step4 = (props) => {


  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);  

  const { loginWithRedirect } = useAuth0();

  const storedWizardData = JSON.parse(localStorage.getItem('wizardData'));
  const button_index = storedWizardData.selectedButtons;
  const buttons = ['History', 'Travel','Adventure','Animals', 'Geography','Science','Sci-Fi','Space','Engineering','Biology', 'Technology'];
  const [called, setCalled] = useState(false);

  const listArray = button_index.map((index) => buttons[index]);
  const [responseData, setResponseData] = useState(null);

  const [loading, setLoading] = useState(true);

  const [selectedBookId, setSelectedBookId] = useState(null);

    const openModal = (src, bookId) => {
      setSelectedImage(src);
      setSelectedBookId(bookId);
    };

  const closeModal = () => {
    setSelectedImage(null);
  };


useEffect(() => {
  const source = axios.CancelToken.source();

  const fetchData = async () => {
    try {
      const res = await axios.get("https://api.abracadabraai.com/review", {
        cancelToken: source.token,

        params: {
          month: storedWizardData.month[0].label,
          year: storedWizardData.year[0].label,
          childName: storedWizardData.childName,
          list_genres: listArray.join(","),
          acct_name: 'not_logged',
          email: 'no_email',
        },
      });
      console.log(res.data);
      setResponseData(res.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("Request canceled");
      } else {
        console.log(err);
      }
    } finally {
      setLoading(false);
    }
  };

  fetchData();

  return () => {
    source.cancel();
  };
}, []); // Empty dependency array






return (
  <Layout>
    <ProgressBar progress={100} />
    <div className="bg-gray-100">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-12" style={{ minHeight: '100vh', paddingBottom: '4rem' }}>
        {responseData ? (
          <>
            <h1 className="font-header text-2xl font-bold leading-normal text-black text-center">Here's what we recommend for {storedWizardData.childName}</h1>

            <div className="flex flex-col sm:flex-row py-12">
              <div className="w-full sm:w-1/5 p-4 bg-white rounded flex flex-col items-center">
                {/* Square Image */}
                <div className="w-full">
                  <img src={responseData.image_url} alt="Example" className="object-cover w-full rounded" />
                </div>
                
                <p className="font-body text-gray-500 mt-4 px-2">{responseData.desc}</p>
              </div>
              <div className="w-full sm:w-4/5 p-4 mt-4 sm:mt-0 sm:ml-4 bg-white shadow-sm rounded">
                {/* Badges */}
                <div className="flex items-center space-x-2 mb-4">
                  <span className="bg-blue-500 font-body text-white py-1 px-3 rounded">{responseData.name}</span>
                  <span className="bg-blue-500 font-body text-white py-1 px-3 rounded">{responseData.age}</span>
                </div>
                {/* Bullet Points */}
                <ul className="font-body text-gray-500 list-disc list-inside mb-4">
                  <li>{responseData.highlights[0]}</li>
                  <li>{responseData.highlights[1]}</li>
                  <li>{responseData.highlights[2]}</li>
                </ul>
                {/* Sample Books */}
                <h2 className="font-header font-bold leading-normal text-black mb-4">Sample Books</h2>
                    <div className="grid grid-cols-4 gap-4">
                      {responseData && responseData.BookDetails.map((book, index) => (
                        <button key={index} onClick={() => openModal(book.CoverUrl, book.bookId)}>
                          <img src={book.CoverUrl} alt={`Book ${index + 1}`} className="w-full object-cover" />
                        </button>
                      ))}
                    </div>
                  </div>
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}

        <div className="fixed bottom-0 left-0 right-0 flex justify-between px-4 sm:px-6 lg:px-8 py-4 bg-white shadow-md items-center" style={{ zIndex: 1000, paddingLeft: '100px', paddingRight: '100px', paddingBottom: '50px', boxShadow: '0 4px 6px rgba(0, 0, 0, 1)', height: '100px' }}>
          <div className="font-body text-gray-500 py-2 rounded-md mt-4" onClick={() => setShowSignIn(false)}>
            
          </div>

        <button className="btn btn-padded py-2 rounded-md mt-4 bg-deep-teal" onClick={() => loginWithRedirect()}>
          <div className="btn-text-container">
            <div className="btn-main-text">Get the Box</div>
            <div className="btn-subtext">Monthly subscription delivered to your door</div>
          </div>
        </button>

        </div>
      </div>
        {selectedImage && (
          <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            style={{ zIndex: 2000 }} // Add a higher zIndex value than the footer
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setSelectedImage(null);
              }
            }}
          >
            <BookDetails bookId={selectedBookId} onClose={() => setSelectedImage(null)} />
          </div>
        )}
    </div>
  </Layout>
  );
};

export default Step4;
