import { Route, Routes, useLocation } from "react-router-dom";
import BookDetails from "./pages/BookDetails";
import Modal from "./pages/Modal";
import Home from "./pages/Home";
import React from 'react';
import Navbar from './components/Navbar';
import Dashboard from './pages/Dashboard';


function App() {
  // Get the current location using useLocation hook

  const location = useLocation();

  return (

      <div className='App'>
        {/* Conditionally render Navbar if not on the modal route */}


            {location.pathname !== '/modal' && <Navbar />}

            <Routes>
              <Route path="/" element={
                  <Home />
              } />

              <Route path="/Modal" element={
                  <Modal />
              } />

              <Route path="/Dashboard" element= {
                  <Dashboard />
              } />

            </Routes>
      </div>
  );
}



export default App;
