import Layout from "../components/Layout";
import Book from "../components/Book";
import React, {useState,useEffect} from 'react';
import axios from 'axios'
import BookDetails from "../pages/BookDetails";
import { Route, Routes } from "react-router-dom";
import { Link } from "react-router-dom";
import Modal from "../pages/Modal";
import Footer from "../components/Footer";
import CenteredVideo from '../components/CenteredVideo';
import { useNavigate } from 'react-router-dom';



export default function Home() {

  const [users, setUsers] = useState([])
  const [isLoaded, setIsLoaded] = useState(false);
  const [isWizardClicked, setIsWizardClicked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [posts,setPosts] = useState ([])

  const videoSrc = 'https://s3.us-west-2.amazonaws.com/abracadabraai.com/src/assets/landing_vid_shrunk.mov';
  const navigate = useNavigate();

    console.log("version:3.0")


    function openModalWizard() {

        setIsWizardClicked(true);
        navigate('/modal'); // Navigate to the modal route
    }
 

  return (

    <Layout >
      {isWizardClicked && 
        <Modal/>

      }
      {!isWizardClicked && isLoaded &&
            <BookDetails data={window.bookId}/>
          }
      {!isWizardClicked && !isLoaded && <div>
      <div className="bg-image bg-soft-yellow">
        <div className="container px-4 py-12 mx-auto w-full flex justify-center">
            <div className="w-full text-center ">
              <h1 className="font-header text-center text-2xl font-bold leading-small text-black pb-2">
                Custom, age-based books delivered to your doorstep
              </h1>
              <p className="font-body text-gray-500 mb-2">
                Nurture, curate and grow your child's library with experts to create books just for them.
              </p>
              <div className="btn py-12 rounded-md mt-4 bg-deep-teal" onClick={openModalWizard}>
                  Let's go!
              </div>
              <div>
                <CenteredVideo videoSrc={videoSrc} />
              </div>
            </div>
        </div>
      </div>


<div className="parent-container">
  <div class="bg-gray-50 pb-8">
    <div class="py-20 max-w-5xl mx-auto px-4 sm:px-0">
      <div>
          <h1 className="font-header text-center text-2xl font-bold leading-small text-soft-pink">Shape the developing brain through a deliberate reading habit</h1>
          <p className="font-body py-6 text-center text-gray-500">Our expert wizard guides you through your child's development stage. Couple our recommendations with your child's interests to build a customized reading experience. </p>
      </div>

      <div class="container mx-4 sm:mx-0">
          <div class="flex items-center p-4 sm:p-0 justify-center">
              <div class="w-full sm:w-4/5">
                  <div class="grid grid-cols-1 md:grid-cols-3 gap-12">
                      <div className="w-full px-4 py-12">
                          <img src="https://s3.us-west-1.amazonaws.com/sindunarasimhan.com/book_illus_1.gif" alt="First GIF" class="object-cover w-full h-auto"/>
                          <p className="font-body text-black text-center mb-2">
                            Let us know your age and reading genre preferences 
                          </p>
                      </div>
                      <div className="w-full px-4 py-12">
                          <img src="https://s3.us-west-1.amazonaws.com/sindunarasimhan.com/book_illus_2.gif" alt="Second GIF" class="object-cover w-full h-auto"/>
                          <p className="font-body text-black text-center mb-2">
                              Pick & customize books working with our designers 
                          </p>
                      </div>
                      <div className="w-full px-4 py-12">
                          <img src="https://s3.us-west-1.amazonaws.com/sindunarasimhan.com/book_illus_3.gif" alt="Third GIF" class="object-cover w-full h-auto"/>
                          <p className="font-body text-black mb-2 text-center">
                            Get it delivered to your door, and watch it grow with your reader  
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>

    </div> 
</div>




    </div>



     <div class=" justify-center pt-20 bg-soft-yellow">
           <h1 className="font-header text-center text-2xl font-bold leading-small text-black">Custom made just for your child</h1>
          <p className="font-body text-gray-500 text-center py-8 w-2/4 mx-auto"> Take the worry out of getting the right book for your child's age. Our boxes are stage-based, and fully customizable to suit your child's preferences and development. Our designers will help make each book your own. Only receive what you love. </p>

        <div className="w-3/4 px-4 py-12 grid grid-cols-1 md:grid-cols-2 gap-0 mx-auto">
          <div class="flex flex-col items-center justify-center">
              <div className="flex flex-col items-start justify-center w-3/4">
                <span class="badge badge-primary">0-1 Year</span>
                <h1 className="font-header py-4 text-xl font-bold leading-normal text-soft-pink mb-2 text-left">
                  Tiny Readers
                </h1>
                <p className="font-body text-left text-gray-500 w-full mb-2">
                  Foundation for reading and focus start early. Build visual and depth perceptions with black/white books, simple rhymes and colorfully curated images. 
                </p>
                <div className="btn py-2 rounded-md mt-4 text-left" onClick={openModalWizard}>
                  Build the box!
                </div>
              </div>

          </div>
          <div className="flex flex-col justify-center items-center">
            <img
              src="https://s3.us-west-2.amazonaws.com/abracadabraai.com/src/assets/toddler_book.png"
              alt="Toddler Book"
              className="w-full md:w-auto h-auto md:h-[54vh] object-cover"
            />
          </div>
        </div>

      <div className="w-3/4 px-4 py-12 grid grid-cols-1 md:grid-cols-2 gap-0 mx-auto">
          <div className="flex flex-col justify-center items-center">
            <img
              src="https://s3.us-west-2.amazonaws.com/abracadabraai.com/src/assets/toddler_2.png"
              alt="Toddler Book"
              className="w-full md:w-auto h-auto md:h-[54vh] object-cover"
            />
          </div>
          <div class="flex flex-col items-center justify-center">
              <div className="flex flex-col items-start justify-center w-3/4">
                <span class="badge badge-primary">1-2 Years</span>
                <h1 className="font-header py-4 text-xl font-bold leading-normal text-soft-pink mb-2 text-left">
                  Young Readers
                </h1>
                <p className="font-body text-left text-gray-500 w-full mb-2">
                   Kick off the exciting milestone of interactive reading! Explore shapes, colors and concepts custom made for your child, and curate as they begin showing specific interests.
                </p>
                <div className="btn py-2 rounded-md mt-4 text-left" onClick={openModalWizard}>
                  Build the box!
                </div>
              </div>

          </div>
        </div>


        <div className="w-3/4 px-4 py-12  grid grid-cols-1 md:grid-cols-2 gap-0 mx-auto">
          <div class="flex flex-col items-center justify-center pb-12">
              <div className="flex flex-col items-start justify-center w-3/4">
                <span class="badge badge-primary">2-3 Year</span>
                <h1 className="font-header py-4 text-xl font-bold leading-normal text-soft-pink mb-2 text-left">
                  Little Schoolers
                </h1>
                <p className="font-body text-left text-gray-500 w-full mb-2">
                  Things start getting exciting now. Your preschooler is beginning to recognize rhymes, their names in books and even complete some sentences. Pick their favorite books and read them again and again and again!
                </p>
                <div className="btn py-2 rounded-md mt-4 text-left" onClick={openModalWizard}>
                  Build the box!
                </div>
              </div>

          </div>
          <div className="flex flex-col justify-center items-center pb-12">
            <img
              src="https://s3.us-west-2.amazonaws.com/abracadabraai.com/src/assets/toddler_3.png"
              alt="Toddler Book"
              className="w-full md:w-auto h-auto md:h-[54vh] object-cover"
            />
          </div>
        </div>


    </div>

    <div>
      <Footer/>
    </div>

    </div>

    }
    </Layout>

  );
}

