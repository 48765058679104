import React, { createContext, useContext, useState } from 'react';

const WizardContext = createContext();

export const useWizardData = () => {
  return useContext(WizardContext);
};

export const WizardDataProvider = ({ children }) => {
  const [wizardData, setWizardData] = useState({});

  const updateWizardData = (key, value) => {
    setWizardData((prevState) => ({ ...prevState, [key]: value }));
  };

  return (
    <WizardContext.Provider value={{ wizardData, updateWizardData }}>
      {children}
    </WizardContext.Provider>
  );
};
