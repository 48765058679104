import React from 'react';

const ProgressBar = ({ progress }) => (
  <div className="bg-gray-200 h-2 relative">
    <div
      className="bg-deep-teal absolute left-0 top-0 h-2"
      style={{ width: `${progress}%` }}
    ></div>
  </div>
);

export default ProgressBar;