// CenteredVideo.js
import React, { useRef, useEffect } from 'react';

const CenteredVideo = ({ videoSrc }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const handleVideoEnded = () => {
      setTimeout(() => {
        videoElement.play();
      }, 3000);
    };

    videoElement.addEventListener('ended', handleVideoEnded);
    return () => {
      videoElement.removeEventListener('ended', handleVideoEnded);
    };
  }, []);

  return (
    <div className="flex items-center justify-center h-[80vh] py-5 md:py-5">
      <video
        ref={videoRef}
        className="max-w-full max-h-full"
        autoPlay
        muted
        loop
        onEnded={() => setTimeout(() => videoRef.current.play(), 3000)}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default CenteredVideo;
