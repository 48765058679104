import * as React from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { useWizardData } from './wizardContext';
import axios from 'axios';
import ProgressBar from '../components/ProgressBar';


const Step3 = (props) => {
          const { handleStep, previousStep, nextStep } = useWizard();
          const { wizardData, updateWizardData } = useWizardData();
          const [selectedButtons, setSelectedButtons] = useState(wizardData.selectedButtons || []);
          const [showSignIn, setShowSignIn] = useState(false);

          const isSubmitDisabled = selectedButtons.length < 4;

          const buttonClass = (option) => {
              return `btn ${selectedOption === option ? 'bg-deep-teal' : 'btn-outline'}`;
          };

          const buttons = ['History', 'Travel','Adventure','Animals', 'Geography','Science','Sci-Fi','Space','Engineering','Biology', 'Technology'];


          const handleSubmit = () => {
            nextStep();
          };

          const handleButtonClick = (index) => {
            if (selectedButtons.includes(index)) {
              setSelectedButtons(selectedButtons.filter((i) => i !== index));
            } else {
              setSelectedButtons([...selectedButtons, index]);
            }
          };

        useEffect(() => {
            updateWizardData('selectedButtons', selectedButtons);
        }, [selectedButtons]);

          handleStep(() => {
          });


          React.useEffect(() => {
                localStorage.setItem('wizardData', JSON.stringify(wizardData));

            });
    
    return (
        <Layout>
            <ProgressBar progress={75} />
            <div className="flex justify-center px-4 md:px-16 lg:px-32 xl:px-64 h-screen items-center min-h-screen pb-16">
                <div class="stepper active" data-step>
                    <h1 class="font-header text-2xl font-bold leading-normal text-black step-title">Which of the following might interest them? </h1>
                    <p className="font-body py-1 text-gray-500">
                        Pick 4 popular genres that will be popular with this
                    </p>

                    <div className="button-group py-12">
                        <div className="flex space-x-2">
                            {buttons.slice(0, 4).map((button, index) => (
                                <div
                                    key={index}
                                    className={`btn btn-outline${selectedButtons.includes(index) ? ' selected' : ''}`}
                                    onClick={() => handleButtonClick(index)}
                                >
                                    {button}
                                </div>
                            ))}
                        </div>
                        <div className="flex mt-2 space-x-2">
                            {buttons.slice(4, 8).map((button, index) => (
                                <div
                                    key={index + 4}
                                    className={`btn btn-outline${selectedButtons.includes(index + 4) ? ' selected' : ''}`}
                                    onClick={() => handleButtonClick(index + 4)}
                                >
                                    {button}
                                </div>
                            ))}
                        </div>

                        <div className="flex mt-2 space-x-2">
                            {buttons.slice(8, 11).map((button, index) => (
                                <div
                                    key={index + 8}
                                    className={`btn btn-outline${selectedButtons.includes(index + 8) ? ' selected' : ''}`}
                                    onClick={() => handleButtonClick(index + 8)}
                                >
                                    {button}
                                </div>
                            ))}
                        </div>
                    </div>

                  <div className="fixed bottom-0 left-0 right-0 flex justify-between px-4 sm:px-6 lg:px-8 py-4 bg-white shadow-md items-center" style={{ zIndex: 10, paddingLeft: '100px', paddingRight: '100px', paddingBottom: '50px', boxShadow: '0 4px 6px rgba(0, 0, 0, 1)', height: '100px' }}>
                      <div className="font-body text-gray-500 py-2 rounded-md mt-4 cursor-pointer" onClick={() => previousStep()}>
                        &lt; Back
                      </div>
                        <div
                            className={`btn py-2 rounded-md mt-4 ${isSubmitDisabled ? 'pointer-events-none' : 'bg-deep-teal'}`}
                            onClick={() => !isSubmitDisabled && handleSubmit()}
                            disabled={isSubmitDisabled}
                            style={{
                              backgroundColor: isSubmitDisabled ? 'rgba(255, 127, 110, 0.5)' : '',
                              color: 'white',
                            }}
                          >
                            Next
                          </div>                    
                      </div>

                </div>
            </div>
        </Layout>
   );
}

export default Step3;

