import * as React from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import { WizardDataProvider } from '../components/wizardContext';


import Layout from "../components/Layout";
import Step1 from "../components/Step1";
import Step2 from "../components/Step2";
import Step3 from "../components/Step3";
import Step4 from "../components/Step4";


const Modal = () => {


    return(
        <Layout> 
            <WizardDataProvider>
                <Wizard>
                    <Step1 />
                    <Step2 />
                    <Step3 />
                    <Step4 />
                </Wizard>
            </WizardDataProvider>
        </Layout>
    );
}

export default Modal;
