import * as React from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import Layout from '../components/Layout';
import Select from 'react-dropdown-select';
import { useWizardData } from './wizardContext';
import ProgressBar from '../components/ProgressBar';
import { useNavigate } from 'react-router-dom';


const Step1 = (props) => {
  const { handleStep, previousStep, nextStep, goToStep } = useWizard();
  const { wizardData, updateWizardData } = useWizardData();
  const navigate = useNavigate();


  // Your existing code
  const Months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },

  ];

  const Years = [
    { value: 2020, label: '2020' },
    { value: 2021, label: '2021' },
    { value: 2022, label: '2022' },

  ];

  const [childName, setChildName] = React.useState('');
  const [month, setMonth] = React.useState(Months[0]); // Set initial month to January
  const [year, setYear] = React.useState(Years[2]); // Set initial year to 2020

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    // Update the context when the state is updated
  React.useEffect(() => {
    updateWizardData('childName', childName);
    updateWizardData('month', month);
    updateWizardData('year', year);
  }, [childName, month, year]);


  return (
    <Layout>
      <ProgressBar progress={25} />

    <div
      style={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 100px)', // Adjust according to the footer height
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 100px 100px 100px', height: '100vh', alignItems: 'center' , minHeight: '100vh', paddingBottom: '4rem' }}>
        <div className="stepper active py-2 flex-row" data-step>
          {/* Your existing code */}
          <div className="form-group">
              <h1 class="font-header text-2xl font-bold leading-normal text-black step-title">Tell us a little about the child</h1>
              <p className="font-body py-1 text-gray-500"> This will help us create a personalized box of your child's favorite books.</p>
            <div class="py-4">
              <label className="label font-body">
                <span>Child's name</span>
              </label>
              <input
                type="text"
                placeholder="Name"
                data-theme="corporate"
                className="input input-bordered input-sm w-full max-w-xs font-body"
                value={childName}
                onChange={(e) => setChildName(e.target.value)}
              />
            </div>

            <div className="py-4 space-y-1">
              <label className="label font-body">
                <span>Birthday</span>
              </label>
              <Select
                className="label font-body text-black"
                options={Months}
                placeholder="Select Month"
                value={month}
                onChange={(values) => setMonth(values)}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={() => setIsMenuOpen(false)}
              />
              <Select
                className="label font-body text-black"
                options={Years}
                placeholder="Select Year"
                value={year}
                onChange={(values) => setYear(values)}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={() => setIsMenuOpen(false)}
              />
            </div>
          </div>
          
          <div className="fixed bottom-0 left-0 right-0 flex justify-between px-4 sm:px-6 lg:px-8 py-4 bg-white shadow-md items-center cursor-pointer" style={{ zIndex: 10, paddingLeft: '100px', paddingRight: '100px', paddingBottom: '50px', boxShadow: '0 4px 6px rgba(0, 0, 0, 1)', height: '100px' }}>
          <div
            className="font-body text-gray-500 py-2 rounded-md mt-4"
            onClick={() => navigate('/')}
          >
            &lt; Back
          </div>


            <div
              className={`btn py-2 rounded-md mt-4 bg-deep-teal ${!childName || !month || !year ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => {
                if (childName && month && year) {
                  nextStep();
                }
              }}
            >
              Next
            </div>

            </div>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default Step1;
