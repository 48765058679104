import React, { useEffect, useState } from 'react';
import axios from 'axios';

const OrderHistory = ({ customerId }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get('https://api.abracadabraai.com/orders', {
        params: {
          customer_id: customerId,
        },
      })
      .then((response) => {
        setOrders(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [customerId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (orders.length === 0) {
    return (
      <div className="flex flex-col items-center py-12 justify-center">
        <div className="empty-icon-container mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
          </svg>
        </div>
        <h1 className="text-xl font-header font-bold mb-2 text-black">Order History</h1>
        <p className="text-gray-600 font-body text-center">You don't have any orders yet.</p>
        <p className="text-gray-600 font-body text-center">Once you place an order, it will appear here.</p>
      </div>
    );
  }

  return (
    <div className="pt-8 pb-8 px-4 sm:px-6 lg:px-8">
      <h1 className="text-xl font-header font-bold mb-2 text-black">Order History</h1>
      <div className="pt-4 space-y-4">
        {orders.map((order) => (
          <div key={order.id} className="bg-white shadow-sm rounded-md p-4">
            <div className="flex flex-col sm:flex-row items-center justify-between space-y-2 sm:space-y-0 sm:space-x-4 p-2">
              <p className="text-gray-600 font-body">Order ID: {order.id}</p>
              <p className="text-gray-600 font-body">Shipped on: {order.shipped_date}</p>
              <p className="text-gray-600 font-body">Total Price: {order.Total_price}</p>
              <p className="text-gray-600 font-body">Number of books: {order.books_count}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderHistory;
