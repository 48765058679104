import React, { useState, useEffect } from 'react';
import useChatwoot from '../components/useChatwoot';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import BookDetails from './BookDetails';
import { useAuth0 } from '@auth0/auth0-react';
import Feed from '../components/Feed';
import OrderHistory from '../components/OrderHistory';


const Dashboard = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [showChatwoot, setShowChatwoot] = useState(false);
  const [activeComponent, setActiveComponent] = useState('Dashboard');
  const { isAuthenticated, loginWithRedirect, logout, isLoading, user } = useAuth0();
  const [feedMessages, setFeedMessages] = useState([]);
  var defaultProfileImage = 'https://via.placeholder.com/150';
  var ordered = false;
  var shipped = false;
  var stripe_is_paid = false;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [childName, setChildName] = useState('');

  const storedWizardData = JSON.parse(localStorage.getItem('wizardData'));
  const [showPricingModal, setShowPricingModal] = useState(false);
  let button_index = [];


  if (storedWizardData) {
      button_index = storedWizardData.selectedButtons;
  }
  const [selectedButtons, setSelectedButtons] = useState(button_index);

  const buttons = ['History', 'Travel','Adventure','Animals', 'Geography','Science','Sci-Fi','Space','Engineering','Biology', 'Technology'];

  const listArray = button_index.map((index) => buttons[index]);
  const [responseData, setResponseData] = useState(null);

  const [loading, setLoading] = useState(true);

  const [selectedBookId, setSelectedBookId] = useState(null);

  const handleButtonClick = (index) => {
    if (selectedButtons.includes(index)) {
      // If the button is already selected, remove it from the selectedButtons array
      setSelectedButtons(selectedButtons.filter((i) => i !== index));
    } else {
      // If the button is not selected, add it to the selectedButtons array
      setSelectedButtons([...selectedButtons, index]);
    }
  };


    if(user) {
      defaultProfileImage = user.picture;
    }


  const togglePricingModal = () => {
    setShowPricingModal(!showPricingModal);
  };

  const openModal = (src, bookId) => {
    setSelectedImage(src);
    setSelectedBookId(bookId);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

useEffect(() => {
  const chatwootWidget = document.querySelector('.woot-widget-holder');
  if (chatwootWidget) {
    if (activeComponent === 'Dashboard') {
      // Make the Chatwoot widget visible when the "Dashboard" tab is active
      chatwootWidget.style.opacity = '1';
      chatwootWidget.style.pointerEvents = 'auto';
    } else {
      // Hide the Chatwoot widget when any other tab is active
      chatwootWidget.style.opacity = '0';
      chatwootWidget.style.pointerEvents = 'none';
    }
  }
}, [activeComponent]);

  // Placeholder function for handling the password change action
  const handleChangePassword = () => {
    // Logic for handling password change goes here
    console.log('Change password button clicked');
  };

useEffect(() => {
  if (user && loading) {
    console.log("here")
    axios
      .get("https://api.abracadabraai.com/review", {
        params: {
          month: storedWizardData?.month[0].label,
          year: storedWizardData?.year[0].label,
          childName: storedWizardData?.childName,
          list_genres: listArray.join(","),
          acct_name: user.sub,
          email: user.email,

        },
      })
      .then((res) => {
      console.log(res.data);
      setResponseData(res.data);
      setLoading(false);

      setFeedMessages([
        // Existing message
        {
          avatar: 'https://s3.us-west-2.amazonaws.com/abracadabraai.com/src/assets/avatar.png',
          header: 'Pending confirmation: Shipping on Apr 27',
          showConfirmAndPayButton: true,
          timestamp: 'now',
          text: res.data.desc,
          BookDetails: res.data.BookDetails,
          badgeName: res.data.name,
          badgeAge: res.data.age,
          bulletList: res.data.highlights.slice(0, 3),
          showOptionalDiv: true,
          optionalMessage: 'Every child is unique. Milestones listed below act as our guidelines. Reach out to our designers to customize your upcoming order to meet your child where they are developmentally.',
          ordered:res.data.ordered,
          shipped:res.data.shipped,
          stripe_is_paid: res.data.stripe_is_paid,
        },
      ]);


    })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }
}, [loading, user, responseData]);


  useChatwoot();



  const handleSignOut = async () => {
  };

  const handleOpenChatwoot = () => {
  if (activeComponent !== 'Dashboard') {
    return;
  }

    setShowChatwoot(true);

    if (window.chatwootSDK) {
      window.chatwootSDK.toggle();
      window.chatwootSDK.sendMessage('Starting conversation...');
    }

  };




  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
      <div className="w-full md:w-1/4 p-8 mr-12 md:mr-12 ml-8 md:ml-16 p-2 md:p-12">
        <div className="bg-white rounded-lg shadow-md p-10">
          <ul>
            <li className="mb-2">
              <button onClick={() => setActiveComponent('Dashboard')} className={`font-body focus:outline-none ${activeComponent === 'Dashboard' ? 'text-soft-pink' : 'text-gray-600'} hover:text-soft-pink`}>
                Dashboard
              </button>
           
            </li>
            <li className="mb-2">
              <button onClick={() => setActiveComponent('updatePreferences')} className={`font-body focus:outline-none ${activeComponent === 'updatePreferences' ? 'text-soft-pink' : 'text-gray-600'} hover:text-soft-pink`}>
               Preferences
              </button>
            </li>
            <li className="mb-2">
              <button onClick={() => setActiveComponent('orderHistory')} className={`font-body focus:outline-none ${activeComponent === 'orderHistory' ? 'text-soft-pink' : 'text-gray-600'} hover:text-soft-pink`}>
                Order History
              </button>
            </li>
            <li className="mb-2">
              <button onClick={() => setActiveComponent('manageAccount')} className={`font-body focus:outline-none ${activeComponent === 'manageAccount' ? 'text-soft-pink' : 'text-gray-600'} hover:text-soft-pink`}>
                Manage Account
              </button>
            </li>

          </ul>
        </div>
      </div>

      <div className="w-full md:w-3/4 mb-4 md:mb-4 mr-2 md:mr-0 ml-4 md:ml-2 p-2 md:p-6">
        {activeComponent === 'Dashboard' && (

          <div className="component-container"> 
            <div className="mt-8">
                <Feed messages={feedMessages} openModal={openModal} togglePricingModal={togglePricingModal} />
                {showPricingModal && (
                  <Pricing onClose={togglePricingModal} />
                )}
            </div>
          </div>

        )}
        {activeComponent === 'updatePreferences' && (
            <div className="component-container">
                <h1 className="font-header text-xl font-bold pt-8 leading-normal text-black">
                  Genres chosen for {storedWizardData && storedWizardData.childName ? storedWizardData.childName : 'your child'}'s books
                </h1>
                <p className="font-body py-1 text-gray-900 py-4"> Keep your preferences updated as your child's reading preferences change. These changes will automatically be reflected in your upcoming order</p>
                <div className="button-group py-4">
                      <div className="flex space-x-2">
                        {buttons.slice(0, 4).map((button, index) => (
                          <div
                            key={index}
                            className={`btn btn-outline${selectedButtons.includes(index) ? ' selected' : ''}`}
                            onClick={() => handleButtonClick(index)}
                          >
                            {button}
                          </div>
                        ))}
                      </div>
                      <div className="flex mt-2 space-x-2">
                        {buttons.slice(4, 8).map((button, index) => (
                          <div
                            key={index + 4}
                            className={`btn btn-outline${selectedButtons.includes(index + 4) ? ' selected' : ''}`}
                            onClick={() => handleButtonClick(index + 4)}
                          >
                            {button}
                          </div>
                        ))}
                      </div>
                    </div>
              </div>
        )}

        {activeComponent === 'orderHistory' && (
          <div className="component-container">
            <OrderHistory customerId={user.sub} /> 
          </div>
        )}

        {activeComponent === 'manageAccount' && (
          <div className="component-container"> 
                  <h1 className="text-xl font-header text-black mt-8">Manage Account</h1>
                  <p className="font-body text-gray-500 mb-2"> Customize and save your account related settings </p>

                  <div className="mt-4 mb-6">
                    {/* Profile picture placeholder */}
                    <div
                      style={{
                        width: '80px', // Width of the placeholder
                        height: '80px', // Height of the placeholder
                        borderRadius: '50%', // Make it circular
                        backgroundImage: `url(${defaultProfileImage})`, // Set the background image
                        backgroundSize: 'cover', // Cover the entire area
                        backgroundPosition: 'center', // Center the image
                      }}
                    ></div>
                  </div>
                 
                  <div className="mt-4">
                    <input
                      type="text"
                      placeholder="Child's Name"
                      data-theme="corporate"
                      className="input input-bordered input-sm w-full font-body"
                      value={childName}
                      onChange={(e) => setChildName(e.target.value)}
                      style={{ width: '300px' }} // Set the desired width here
                    />
                  </div>

                  <div className="mt-2">
                    <input
                      type="email"
                      placeholder="email"
                      data-theme="corporate"
                      className="input input-bordered input-sm w-full font-body"
                      value={user.email}
                      onChange={(e) => setChildName(e.target.value)}
                      style={{ width: '300px' }} // Set the desired width here
                    />
                      </div>

                      <button className="mt-8 btn btn-outline"> Save </button>

                      <hr className="mt-4" />
                        <h1 className="text-xl font-header text-black mt-8">Manage subscription</h1>
                        <p className="font-body text-gray-500 mb-2"> Your montly subscription delivers customized books right to your doorstep. Cancel anytime! No commitments. </p>
                        <button className="mt-8 btn" style={{ backgroundColor: 'red' }}> Cancel Subscription </button>

                      <hr className="mt-4" />
                        <h1 className="text-xl font-header text-black mt-8">Logout</h1>
                        <p className="font-body text-gray-500 mb-2"> Log out to access a new account. Your current preferences and details will be stored if you choose to log back in. </p>
                          <button className="btn btn-outline mt-4" onClick={() => (isAuthenticated ? logout() : loginWithRedirect())}>
                            {isAuthenticated ? 'Logout' : 'Login'}
                          </button>
                        <hr className="mt-4" />





                   </div>


        )}

      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 2000 }} // Add a higher zIndex value than the footer
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setSelectedImage(null);
            }
          }}
        >
          <BookDetails bookId={selectedBookId} onClose={() => setSelectedImage(null)} />
        </div>
      )}
    </div>
  );
};

export default Dashboard;

