import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';

const Navbar = () => {
  const { isAuthenticated, loginWithRedirect, logout, isLoading, user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  // Redirect user to the dashboard if authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  // Check if the current route is the dashboard
  const isDashboard = location.pathname === '/dashboard';

  return (
    <div className="bg-soft-yellow py-4">
      <div className="max-w-7xl mx-auto navbar">
        <div className="navbar-start">
          <Link to="/" className="logo-container">
            <img src="https://s3.us-west-2.amazonaws.com/abracadabraai.com/public/logo.png" alt="Logo" className="logo" />
          </Link>
        </div>
        <div className="navbar-end">
          {!isDashboard && (
            <button className="btn btn-outline" onClick={() => (isAuthenticated ? logout() : loginWithRedirect())}>
              {isAuthenticated ? 'Logout' : 'Login'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;

