import React from 'react';


const Layout = ({children}) => {

  return (
    <>
      <main>{children}</main>
    </>
  );
}

export default Layout;