import * as React from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import { useState, useEffect } from 'react';
import Layout from "../components/Layout";
import { useWizardData } from './wizardContext';
import ProgressBar from '../components/ProgressBar';

const Step2 = (props) => {
    const { handleStep, previousStep, nextStep } = useWizard();
    const { wizardData, updateWizardData } = useWizardData();
    const [selectedButtons, setSelectedButtons] = useState(wizardData.selectedButtons);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleButtonClick = (option) => {
        setSelectedOption(option);
        updateWizardData({ ...wizardData, selectedButtons: option });
    };

    const buttonClass = (option) => {
        return `btn ${selectedOption === option ? 'selected' : 'btn-outline'}`;
    };


    return (
        <Layout>
            <ProgressBar progress={50} />

            <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 100px 100px 100px', height: '100vh', alignItems: 'center',minHeight: '100vh', paddingBottom: '4rem' }}>
                <div class="stepper active" data-step>
                    <h1 class="font-header text-2xl font-bold leading-normal text-black step-title">What is your child's gender? </h1>
                    <p className="font-body py-1 text-gray-500">
                        This will help us provide better customization options
                    </p>

                    <div class="form-group py-8">
                        <div class="flex flex-col space-y-2">
                            <div className={buttonClass('Male')} onClick={() => handleButtonClick('Male')}>Male</div>
                            <div className={buttonClass('Female')} onClick={() => handleButtonClick('Female')}>Female</div>
                            <div className={buttonClass('Choose not to answer')} onClick={() => handleButtonClick('Choose not to answer')}>Choose not to answer</div>
                        </div>
                    </div>

                  <div className="fixed bottom-0 left-0 right-0 flex justify-between px-4 sm:px-6 lg:px-8 py-4 bg-white shadow-md items-center cursor-pointer" style={{ zIndex: 10, paddingLeft: '100px', paddingRight: '100px', paddingBottom: '50px', boxShadow: '0 4px 6px rgba(0, 0, 0, 1)', height: '100px' }}>
                            <div className="font-body text-gray-500 py-2 rounded-md mt-4" onClick={() => previousStep()}>
                              &lt; Back
                            </div>
                        <div className={`btn py-2 rounded-md mt-4 bg-deep-teal ${selectedOption === null ? 'btn-disabled' : ''}`} onClick={() => selectedOption !== null && nextStep()}>
                            Next
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Step2;
