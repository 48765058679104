import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";



const root = ReactDOM.createRoot(document.getElementById("root"));

        
root.render(
  <React.StrictMode>
      <HashRouter>
        <Auth0Provider
          domain="dev-2oi3vy45i7dzeyxc.us.auth0.com"
          clientId="juqvs0rAeVTUPyBk13WGtedl7SBFerif"
          authorizationParams={{
            redirect_uri: window.location.origin+'/#/dashboard'
          }}
        >
          <App />
        </Auth0Provider>
      </HashRouter>
  </React.StrictMode>
);