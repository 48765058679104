import { useEffect } from 'react';

const useChatwoot = () => {
  useEffect(() => {
    if (window.chatwootSDK) return;

    window.chatwootSettings = {
      type: "expanded_bubble",
      launcherTitle: "Customize upcoming order",
    };

    const script = document.createElement('script');
    script.src = 'https://app.chatwoot.com/packs/js/sdk.js';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      window.chatwootSDK.run({
        websiteToken: 'jvgK3ekfE1Ya6BGjPxhmFoiN',
        baseUrl: 'https://app.chatwoot.com',
      });
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default useChatwoot;
