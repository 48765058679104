import React from 'react';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styles from '../styles/Feed.module.css';

const Feed = ({ messages, openModal, togglePricingModal }) => {
  const { user } = useAuth0();
  const [showModal, setShowModal] = useState(false);

  // Function to toggle the visibility of the modal
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const getUserIdFromSub = (sub) => {
    if (!sub) {
      return null;
    }
    const parts = sub.split('|');
    return parts.length > 1 ? parts[1] : null;
  };

  const goToPricing = (email, userId) => {
    const url = `https://buy.stripe.com/test_cN2fZ67n44zMduo000?prefilled_email=${encodeURIComponent(email)}&client_reference_id=${encodeURIComponent(userId)}`;
    window.open(url, '_blank');
  };

  var userId = '';
  if (user) {
    userId = getUserIdFromSub(user.sub);
  }

  return (
    <div className="space-y-2">
      {messages.map((message, index) => (
        <div key={index} className="flex space-x-4">
          <img src={message.avatar} alt={message.name} className="rounded-full w-16 h-16" />
          <div className="flex flex-col">
            <div className="flex justify-between items-center w-full">
              <div className="flex flex-col">
                <span className="font-bold text-xl text-black font-body">
                  {message.ordered === false && `Confirm now: Ships within a week`}
                  {message.ordered === true && message.shipped === false && `${message.badgeName} box shipping soon!`}
                  {message.shipped === true && `${message.badgeName} box is on it's way!`}
                </span>
                <span className="text-black-600 font-body">{message.timestamp}</span>
              </div>
              {message.showConfirmAndPayButton && (
                <div>
                  <button
                    className="btn bg-deep-teal text-white font-body p-3 rounded"
                    onClick={() => {
                      if (message.ordered === true && message.shipped === false) {
                        toggleModal();
                      } else {
                        goToPricing(user.email, userId);
                      }
                    }}
                  >
                    {message.ordered === false && 'Begin Subscription'}
                    {message.ordered === true && message.shipped === false && "What's Next"}
                    {message.shipped === true && 'Track your order'}
                  </button>
                </div>
              )}
            </div>
            {showModal && (
              <div
                onClick={(e) => {
                  if (e.target === e.currentTarget) {
                    toggleModal();
                  }
                }}
                className={styles.modalOverlay}
              >
                <div className={styles.modalContent}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      className={`${styles.modalStep} ${message.ordered ? styles.modalStepOrdered : ''}`}
                    >
                      <h3 className="text-xl font-body" style={{ color: message.ordered ? '#FF7F6E' : '#000000' }}>Customize Soon!</h3>
                      <img src="https://s3.us-west-2.amazonaws.com/abracadabraai.com/src/assets/Customize.gif" alt="Step 1" />
                      <p className="font-body py-1 text-gray-500">
                        Chat with us to modify books in each box before shipping. 
                      </p>
                    </div>
                    <div
                      className={`${styles.modalStep} ${message.shipped ? styles.modalStepShipped : ''}`}
                    >
                      <h3 className="text-xl font-body" style={{ color: message.shipped ? '#FF7F6E' : '#000000' }}>It Shipped!</h3>
                      <img src="https://s3.us-west-2.amazonaws.com/abracadabraai.com/src/assets/Books_Mailed.gif" alt="Step 2" />
                      <p className="font-body py-1 text-gray-500">
                        Get notified of updates to your phone after your book is shipped.
                      </p>
                    </div>
                    <div
                      className={`${styles.modalStep} ${message.ordered && message.shipped ? styles.modalStepBoth : ''}`}
                    >
                      <h3 className="text-xl font-body" style={{ color: message.ordered && message.shipped ? '#FF7F6E' : '#000000' }}>Make it better!</h3>
                      <img src="https://s3.us-west-2.amazonaws.com/abracadabraai.com/src/assets/Refine.gif" alt="Step 3" />
                      <p className="font-body py-1 text-gray-500">
                        Continuously refine and edit preferences specific to your child.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {message.showOptionalDiv && (
              <div className="p-4 mt-6 mb-4 font-body bg-white opacity-70 shadow-md">
                <p className="text-gray-900">{message.optionalMessage}</p>
              </div>
            )}
            <p className="text-gray-900 font-body">{message.text}</p>
            {message.badgeName && message.badgeAge && (
              <div className="flex items-center space-x-2 mt-4">
                <span className="border border-blue-500 font-body text-blue-500 py-1 px-3 rounded">{message.badgeName}</span>
                <span className="border border-blue-500 font-body text-blue-500 py-1 px-3 rounded">{message.badgeAge}</span>
              </div>
            )}
            {message.bulletList && (
              <ul className="list-disc font-body text-gray-600 pl-4 mt-4">
                {message.bulletList.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            )}
            <div className="grid grid-cols-4 gap-4 pt-8">
              {message.BookDetails && message.BookDetails.map((book, index) => (
                <button key={index} onClick={() => openModal(book.CoverUrl, book.bookId)}>
                  <img src={book.CoverUrl} alt={`Book ${index + 1}`} className="w-full object-cover" />
                </button>
              ))}
            </div>
            {index < messages.length - 1 && <hr className="border-gray-300 my-8" />}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Feed;

