import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import FlipPage from "react-flip-page";
import axios from "axios";

const BookDetails = ({ bookId, title, onClose }) => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.abracadabraai.com/bookDetails", {
        params: {
          bookId: bookId,
        },
      })
      .then((res) => {
        setPages(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [bookId]);

  const pairedPages = [];
  for (let i = 0; i < pages.length; i += 2) {
    pairedPages.push({ left: pages[i], right: pages[i + 1] });
  }

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <FlipPage
          width={1700 * 0.5}
          height={680 * 0.5}
          orientation="horizontal"
          uncutPages={true}
          showHint={true}
          animationDuration={500}
          treshold={1}
          onPageClick={(pageIndex, evt) => {
            if (evt.target === evt.currentTarget) {
              // Check if the click is on the left or right side of the page
              const x = evt.pageX - evt.currentTarget.offsetLeft;
              if (x < evt.currentTarget.offsetWidth / 2) {
                // Left side clicked: go to the previous page
                evt.currentTarget.flip(-1);
              } else {
                // Right side clicked: go to the next page
                evt.currentTarget.flip(1);
              }
            }
          }}
        >
          {pairedPages.map((pair, i) => (
            <article
              key={i}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
              }}
            >
              <div style={{ width: "50%", height: "100%" }}>
                <img
                  src={pair.left}
                  alt={`page-${i * 2}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    display: "block",
                  }}
                />
              </div>
              <div style={{ width: "50%", height: "100%" }}>
                {pair.right && (
                  <img
                    src={pair.right}
                    alt={`page-${i * 2 + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      display: "block",
                    }}
                  />
                )}
              </div>
            </article>
          ))}
        </FlipPage>
      </div>
    </Layout>
  );
};

export default BookDetails;
