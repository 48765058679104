import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer footer-center p-10  bg-deep-teal text-primary-content rounded">
      <div>
        <p className="font-body py-2 text-center text-white mb-0">Copyright © 2023 - All right reserved by Abracadabra AI</p>
      </div>
    </footer>
  );
}

export default Footer;
